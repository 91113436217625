<template>
  <q-form ref="editForm">
    <c-card title="기본정보" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- [S]결재관련 버튼 -->
          <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
          <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
          <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
          <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
          <c-appr-btn 
            ref="appr-btn"
            name="danger-journal-appr-btn"
            :editable="editable"
            :approvalInfo="approvalInfo"
            @beforeApprAction="saveDataAppr"
            @callbackApprAction="approvalCallback"
            @requestAfterAction="getDetail"
          />
          <c-btn v-if="editable && !disabled && isRemove" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="removeData" />
          <c-btn
            v-if="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="data"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="saveJournal"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            required
            :editable="editable"
            :disabled="disabled"
            label="취급일지명"
            name="chmDangerTitle"
            v-model="data.chmDangerTitle">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            required
            :readonly="true"
            :editable="editable"
            label="품명(위험물저장소)"
            name="chmDangerMstName"
            v-model="data.chmDangerMstName"
            @searchDangerMaster="searchDangerMaster"
            @removeDangerMaster="removeDangerMaster"
            :afterIcon="disabled  || updateMode ? null :[
              { name: 'search', click: true, callbackName: 'searchDangerMaster' },
              { name: 'close', click: true, callbackName: 'removeDangerMaster' }
            ]">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 기간 -->
          <c-datepicker
            required
            :range="true"
            name="period"
            :disabled="disabled || updateMode"
            defaultStart="0d"
            defaultEnd="6d"
            label="기간"
            v-model="period"
            @datachange="gridchange"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant 
            required
            :editable="editable" 
            :disabled="disabled"
            type="edit" 
            name="plantCd" 
            v-model="data.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            :disabled="true"
            :editable="editable"
            label="설치장소"
            name="chmDangerArea"
            v-model="data.chmDangerArea">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            :editable="editable"
            :disabled="true"
            label="지정수량 표기"
            name="chmDangerCount"
            v-model="data.chmDangerCount">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            :editable="editable"
            :disabled="true"
            label="주용도"
            name="chmDangerMainUse"
            v-model="data.chmDangerMainUse">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-field
            :editable="editable"
            :data="data"
            :disabled="disabled"
            label="안전관리자"
            type="user"
            name="chmDangerManageUserId"
            v-model="data.chmDangerManageUserId">
          </c-multi-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-field
            :editable="editable"
            :data="data"
            :disabled="disabled"
            label="담당자"
            type="user"
            name="chmDangerUserId"
            v-model="data.chmDangerUserId">
          </c-multi-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-field
            :editable="editable"
            :data="data"
            :disabled="disabled"
            label="안전대리자"
            type="user"
            name="chmDangerSafetyUserId"
            v-model="data.chmDangerSafetyUserId">
          </c-multi-field>
        </div>
      </template>
    </c-card>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-tab
        type="tabcard"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        v-model="tab"
        align="left"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :data="data"
            :itemGrid.sync="itemGrid"
            :prevList.sync="prevList"
            :resultGrid.sync="resultGrid"
            :contentHeight="contentHeight"
            @getDetail="getDetail"
          />
        </template>
      </c-tab>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'danger-journal-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmDangerJournalId: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      editable: true,
      searchUrl: '',
      itemGrid: {
        columns: [
        ],
        height: '450px'
      },
      data: {
        chmDangerJournalId: '',
        plantCd: '',
        chmDangerMstId: '',
        chmDangerTitle: '',
        chmDangerStartDate: '',
        chmDangerEndDate: '',
        chmDangerArea: '',
        chmDangerManageUserId: '',
        chmDangerMstName: '',
        chmDangerUserId: '',
        chmDangerSafetyUserId: '',
        chmDangerCount: '',
        chmDangerMainUse: '',
        chmDangerCompleteFlag: '',
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드

        itemList: [],
        resultList: [],
        imprList: [],
      },
      period: [],
      tab: 'dangerJournalResult',
      tabItems: [
        { name: 'dangerJournalResult', icon: 'list', label: '취급일지 결과', component: () => import(`${'./dangerJournalResult.vue'}`) },
        //{ name: 'dangerJournalItem', icon: 'list', label: '점검항목', component: () => import(`${'./dangerJournalItem.vue'}`) },
        { name: 'dangerJournalImpr', icon: 'health_and_safety', label: '개선관리', component: () => import(`${'./dangerJournalImpr.vue'}`) },
      ],
      resultGrid: {
        merge: [
          { index: 0, colName: 'chmDangerResultDate' },
          { index: 6, colName: 'chmDangerResultDate' },
        ],
        columns: [
          {
            name: 'chmDangerResultDate',
            field: 'chmDangerResultDate',
            label: '날짜',
            align: 'center',
            style: 'width:150px',
            sortable: false,
            fix: true,
          },
          {
            name: 'chmDangerResultName',
            field: 'chmDangerResultName',
            label: '저장품목',
            align: 'center',
            style: 'width:150px',
            sortable: false,
            fix: true,
          },
          {
            name: 'chmDangerIn',
            field: 'chmDangerIn',
            label: '입고량(L/KG)',
            align: 'right',
            type: 'number',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'chmDangerOut',
            field: 'chmDangerOut',
            label: '사용량(L/KG)',
            align: 'right',
            type: 'number',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'chmDangerStock',
            field: 'chmDangerStock',
            label: '재고량(L/KG)',
            align: 'right',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'chmDangerQuan',
            field: 'chmDangerQuan',
            label: '지정수량 배수',
            align: 'right',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'chmDangerQuanSum',
            field: 'chmDangerQuanSum',
            label: '저장소 지정수량 배수(합계)',
            align: 'center',
            type: 'custom',
            style: 'width:150px',
            sortable: false,
          },
          // {
          //   name: 'calcuMethod',
          //   field: 'calcuMethod',
          //   label: '계산방식',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: false,
          // },
          {
            setHeader: true,
            name: 'chmDangerConfirmUserName',
            field: 'chmDangerConfirmUserName',
            label: '확인자',
            align: 'center',
            type: 'user',
            userId: 'chmDangerConfirmUserId',
            style: 'width:150px',
            sortable: false,
          },
          {
            setHeader: true,
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'center',
            type: 'textarea',
            style: 'width:200px',
            sortable: false,
          },
        ],
        prevData: [],
        height: '450px'
      },
      saveUrl: 'transactionConfig.chm.danger.journal.insert.url',
      saveType: 'POST',
      isSave: false,
      getUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      updateMode: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      resDate: [],
      prevList: [],
      isApproval: false,
    };
  },
  computed: {
    approvalInfo() {
      return {
        sysApprovalRequestId: this.data.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.data.approvalStatusCd, 
        apprEditable: this.data.chmDangerCompleteFlag && this.data.chmDangerCompleteFlag !== 'Y', // 결재버튼 활성화 기준
        param: this.data, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000009', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          chmDangerJournalId: this.data.chmDangerJournalId,
          isApprContent: true
        },
        approvalRequestName: '위험물 취급일지 ['+this.data.chmDangerTitle+']', // 결재요청명 (문서 title)
        approvalConnId: this.popupParam.chmDangerJournalId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    disabled() {
     return this.data.chmDangerCompleteFlag === 'Y' || this.data.approvalStatusCd === 'ASC0000001'
    },
    isRemove() {
      return this.popupParam.chmDangerJournalId && Boolean(!this.data.sysApprovalRequestId)
    },
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - 240);
      if (height < 400) {
        height = 500;
      }
      this.itemGrid.height = height + 'px'
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.chm.danger.journal.result.url;
      this.getUrl = selectConfig.chm.danger.journal.get.url;
      this.insertUrl = transactionConfig.chm.danger.journal.insert.url;
      this.updateUrl = transactionConfig.chm.danger.journal.update.url;
      this.deleteUrl = transactionConfig.chm.danger.journal.delete.url;
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.chmDangerJournalId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.chmDangerJournalId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.updateMode = true;
          this.data = this.$_.clone(_result.data);
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.period = [_result.data.chmDangerStartDate, _result.data.chmDangerEndDate];
          if (_result.data.itemList) {
            this.$_.forEach(_result.data.itemList, _item => {
              this.$_.forEach(_item.itemResultList, __item => {
                _item[__item.chmDangerItemDate] = __item.chmDangerEvalCd
              })
            })
          }
          this.gridchange();
          this.getPrevDetail();
        },);
      } else {
        this.gridchange();
      }
    },
    getPrevDetail() {
      if (!this.period) return;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        prevDate: this.period[0],
        chmDangerMstId: this.data.chmDangerMstId,
      }
      this.$http.request((_result) => {
        this.prevList = []
        if (this.data.resultList) {
          this.$_.forEach(_result.data, _item => {
            if (this.$_.findIndex(this.data.resultList, {chmDangerResultName: _item.chmDangerResultName}) >= 0) {
              this.prevList.push(_item);
            } 
          }) 
          this.$_.forEach(this.data.resultList, _item => {
            let __idx = this.$_.findIndex(this.prevList, {chmDangerResultName: _item.chmDangerResultName})
            if (__idx >= 0) {
              // _item.chmDangerStock = this.prevList[__idx].chmDangerStock
            }
          })
        }
      },);
    },
    gridchange() {
      let _columns = [
        {
          name: 'chmDangerItemName',
          field: 'chmDangerItemName',
          label: '점검항목',
          style: 'width:300px',
          align: 'left',
          sortable: false,
          fix: true,
        },
      ]

      this.resDate = [];
      var sDate = new Date(this.period[0])
      var eDate = new Date(this.period[1])
      while(sDate.getTime() <= eDate.getTime()) {
        var _mon = (sDate.getMonth()+1);
        _mon = _mon < 10 ? '0'+_mon : _mon;
        var _day_ = sDate.getDate();
        _day_ = _day_ < 10 ? '0'+_day_ : _day_;
        this.resDate.push(sDate.getFullYear() + '-' + _mon + '-' +  _day_);
        sDate.setDate(sDate.getDate() + 1);
      }

      let itemColumns = [];
      this.$comm.getComboItems('DANGER_RESULT_CD').then(_result => {
        this.$_.forEach(this.resDate, _item => {
          itemColumns.push({
            name: _item,
            field: _item,
            align: 'center',
            label: _item,
            type: 'select',
            isChip: true,
            none: true,
            style: 'width:100px',
            comboItems: _result,
            // setHeader: true,
            sortable: false,
          })
        })
        itemColumns.push({
          name: 'remark',
          field: 'remark',
          label: '비고',
          style: 'width:100px',
          type: 'textarea',
          align: 'left',
          sortable: false,
        })

        this.itemGrid.columns = this.$_.concat(_columns, itemColumns)
        
        if (!this.popupParam.chmDangerJournalId) {
          this.getPrevDetail();
          if (this.data.chmDangerMstId) {
            this.data.itemList = [];
            this.data.resultList = [];
            this.$http.url = this.$format(selectConfig.chm.danger.master.get.url, this.data.chmDangerMstId);
            this.$http.type = 'GET';
            this.$http.request((_result) => {
              if (_result.data.itemList) {
                this.$_.forEach(_result.data.itemList, _item =>{
                  this.data.itemList.push({
                    chmDangerJournalId: this.popupParam.chmDangerJournalId,
                    chmDangerJournalItemId: uid(),
                    chmDangerItemName: _item.chmDangerItemName,
                    sortOrder: _item.sortOrder,
                    remark: _item.remark,
                    itemResultList: [],
                    editFlag: 'C',
                  })
                }) 
                this.$_.forEach(this.resDate, _item => {
                  this.$_.forEach(_result.data.typeList, __item => {
                    this.data.resultList.push({
                      chmDangerJournalId: this.popupParam.chmDangerJournalId,
                      chmDangerJournalResultId: uid(),
                      chmDangerResultDate: _item,
                      chmDangerResultName: __item.chmDangerTypeName,
                      calcuMethod: '재고량/'+ __item.calcuMethod,
                      chmDangerIn: 0,
                      chmDangerOut: 0,
                      chmDangerStock: 0,
                      chmDangerQuan: 0,
                      chmDangerConfirmUserId: '',
                      chmDangerConfirmUserName: '',
                      editFlag: 'C',
                    })
                  })
                  this.$_.forEach(this.data.itemList, __item => {
                    // 데이터에 표시하기 위함.
                    __item[_item] = "DR00000001";
                    // 날짜별 list
                    __item.itemResultList.push({
                      chmDangerJournalId: this.popupParam.chmDangerJournalId,
                      chmDangerJournalItemId: __item.chmDangerJournalItemId,
                      chmDangerJournalItemResultId: uid(),
                      chmDangerItemDate: _item,
                      chmDangerEvalCd: null,
                      editFlag: 'C',
                    })
                  })
                })
                this.data.imprList = this.$_.cloneDeep(this.data.itemList)
              }
              this.getPrevDetail();
            },);
          }
          }
      });
    
    },
    saveJournal() {
      if (this.data.chmDangerJournalId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          if (this.$comm.validTable(this.resultGrid.columns, this.data.resultList)) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.data.regUserId = this.$store.getters.user.userId
                this.data.chgUserId = this.$store.getters.user.userId
                
                if(this.saveType === 'POST') {
                  this.data.chmDangerCompleteFlag = 'N'
                }
                if (this.period && this.period.length > 0) {
                  this.data.chmDangerStartDate = this.period[0]
                  this.data.chmDangerEndDate = this.period[1]
                }

                this.$_.forEach(this.data.itemList, _item => {
                  this.$_.forEach(_item.itemResultList, __item => {
                    __item.chmDangerEvalCd = _item[__item.chmDangerItemDate];
                    if (_item.editFlag === 'U') {
                      __item.editFlag = 'U'
                    }
                  })
                })
                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.chmDangerJournalId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.regUserId = this.$store.getters.user.userId;
          this.data.chgUserId = this.$store.getters.user.userId;

          if (this.period && this.period.length > 0) {
            this.data.chmDangerStartDate = this.period[0]
            this.data.chmDangerEndDate = this.period[1]
          }

          this.$_.forEach(this.data.itemList, _item => {
            this.$_.forEach(_item.itemResultList, __item => {
              __item.chmDangerEvalCd = _item[__item.chmDangerItemDate];
              if (_item.editFlag === 'U') {
                __item.editFlag = 'U'
              }
            })
          })
          let promises = [
            {
              func: this.approvalValue
            },
          ];
          this.$comm.orderedPromise(promises);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.chmDangerJournalId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    searchDangerMaster() {
      this.popupOptions.title = '위험물 저장소 검색'; // 위험물 저장소 검색
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.data.plantCd,
      };
      this.popupOptions.target = () => import(`${'./dangerMasterPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeDangerPopup;
    },
    closeDangerPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.data.itemList = [];
        this.data.resultList = [];
        this.$http.url = this.$format(selectConfig.chm.danger.master.get.url, data[0].chmDangerMstId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data.chmDangerArea = _result.data.chmDangerArea
          this.data.chmDangerManageUserId = _result.data.chmDangerManageUserId
          this.data.chmDangerMstId = _result.data.chmDangerMstId
          this.data.chmDangerMstName = _result.data.chmDangerMstName
          this.data.chmDangerUserId = _result.data.chmDangerUserId
          this.data.chmDangerSafetyUserId = _result.data.chmDangerSafetyUserId
          this.data.chmDangerCount = _result.data.chmDangerCount
          this.data.chmDangerMainUse = _result.data.chmDangerMainUse
          if (_result.data.itemList) {
            this.$_.forEach(_result.data.itemList, _item =>{
              this.data.itemList.push({
                chmDangerJournalId: this.popupParam.chmDangerJournalId,
                chmDangerJournalItemId: uid(),
                chmDangerItemName: _item.chmDangerItemName,
                sortOrder: _item.sortOrder,
                remark: _item.remark,
                itemResultList: [],
                editFlag: 'C',
              })
            }) 
            this.$_.forEach(this.resDate, _item => {
              this.$_.forEach(_result.data.typeList, __item => {
                this.data.resultList.push({
                  chmDangerJournalId: this.popupParam.chmDangerJournalId,
                  chmDangerJournalResultId: uid(),
                  chmDangerResultDate: _item,
                  chmDangerResultName: __item.chmDangerTypeName,
                  calcuMethod: '재고량/'+ __item.calcuMethod,
                  chmDangerIn: 0,
                  chmDangerOut: 0,
                  chmDangerStock: 0,
                  chmDangerQuan: 0,
                  chmDangerQuanSum: null,
                  chmDangerConfirmUserId: '',
                  chmDangerConfirmUserName: '',
                  editFlag: 'C',
                })
              })
              this.$_.forEach(this.data.itemList, __item => {
                // 데이터에 표시하기 위함.
                __item[_item] = "DR00000001";
                // 날짜별 list
                __item.itemResultList.push({
                  chmDangerJournalId: this.popupParam.chmDangerJournalId,
                  chmDangerJournalItemId: __item.chmDangerJournalItemId,
                  chmDangerJournalItemResultId: uid(),
                  chmDangerItemDate: _item,
                  chmDangerEvalCd: null,
                  editFlag: 'C',
                })
              })
            })
            this.data.imprList = this.$_.cloneDeep(this.data.itemList)
          }
          this.getPrevDetail();
        },);
      }
    },
    removeDangerMaster() {
      this.data.chmDangerArea = '';
      this.data.chmDangerManageUserId = '';
      this.data.chmDangerMstName = '';
      this.data.chmDangerUserId = '';
      this.data.chmDangerSafetyUserId = '';
      this.data.chmDangerCount = '';
      this.data.chmDangerMainUse = '';
      this.data.itemList = [];
      this.data.resultList = [];
    },
  }
};
</script>
